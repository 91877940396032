import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import Section from "components/Section";
import { useHeader, useIsInEditMode, usePageContent } from "hooks";
import { addEditAttributes } from "utils/episerver";

import {
    Container,
    Content,
    Heading,
    Introduction,
    StyledForm,
    MultiStepContent,
    MultiStepWrapper,
} from "./FormSection.styled";
import FormSectionProps from "./FormSectionProps";

const FormSection = ({
    formId,
    identifier,
    theme = "lightgray",
    contentTheme = "black",
    actionTheme = "cyan",
    heading,
    introduction,
    displayOption = "Left",
    formElements,
    gtmFormType,
    postUrl,
    spamTrapUrl,
    anchorName,
    l18n,
    size,
    formActors,
}: FormSectionProps): ReactElement => {
    const isInEditMode = useIsInEditMode();
    const headerState = useHeader();
    const pageContent = usePageContent();

    const formGuide = pageContent.component === "FormGuidePage";

    const isMultiStepForm =
        formElements &&
        formElements.some(
            (item) =>
                item.component === "FormStepBlock" ||
                item.component === "FormResultStepBlock" ||
                item.component === "FormSummaryStepBlock",
        );

    // This is a fallback for the LightGrayBlackBlack theme issue with non existing buttons in color black
    if (
        theme === "lightgray" &&
        contentTheme === "black" &&
        actionTheme === "black"
    )
        actionTheme = "cyan";

    return (
        <Section theme={theme} id={anchorName} alignItems="center">
            <ThemeProvider
                theme={{
                    theme: theme,
                    contentTheme: contentTheme,
                    displayOption: displayOption,
                    isMultiStepForm: isMultiStepForm,
                    formGuide: formGuide || false,
                }}
            >
                {isMultiStepForm ? (
                    <MultiStepWrapper key={identifier || `FormSectionContent`}>
                        {formGuide ? (
                            <Container>
                                {(isInEditMode || formElements) && (
                                    <StyledForm
                                        id={formId}
                                        items={formElements || []}
                                        areaName="FormElements"
                                        action={postUrl}
                                        identifier={identifier}
                                        l18n={l18n}
                                        size={size}
                                        actionTheme={actionTheme}
                                        gtmFormType={gtmFormType}
                                        formGuide={formGuide}
                                        formGuideHeading={heading}
                                        formGuideIntroduction={introduction}
                                    />
                                )}
                            </Container>
                        ) : (
                            <>
                                <MultiStepContent>
                                    {(isInEditMode || heading) && (
                                        <Heading
                                            as="h2"
                                            {...addEditAttributes("Title")}
                                            dangerouslySetInnerHTML={{
                                                __html: heading || "",
                                            }}
                                        />
                                    )}
                                    {(isInEditMode || introduction) && (
                                        <Introduction
                                            {...addEditAttributes(
                                                "Description",
                                            )}
                                        >
                                            {introduction}
                                        </Introduction>
                                    )}
                                </MultiStepContent>
                                {(isInEditMode || formElements) && (
                                    <StyledForm
                                        id={formId}
                                        items={formElements || []}
                                        areaName="FormElements"
                                        action={postUrl}
                                        identifier={identifier}
                                        l18n={l18n}
                                        size={size}
                                        actionTheme={actionTheme}
                                        gtmFormType={gtmFormType}
                                        formActors={formActors}
                                    />
                                )}
                            </>
                        )}
                    </MultiStepWrapper>
                ) : (
                    <Container key={identifier || `FormSectionContent`}>
                        <Content $headerVisible={headerState.visible}>
                            {(isInEditMode || heading) && (
                                <Heading
                                    as="h2"
                                    {...addEditAttributes("Title")}
                                    dangerouslySetInnerHTML={{
                                        __html: heading || "",
                                    }}
                                />
                            )}
                            {(isInEditMode || introduction) && (
                                <Introduction
                                    {...addEditAttributes("Description")}
                                >
                                    {introduction}
                                </Introduction>
                            )}
                        </Content>
                        {(isInEditMode || formElements) && (
                            <StyledForm
                                id={formId}
                                items={formElements || []}
                                areaName="FormElements"
                                action={postUrl}
                                spamTrapAction={spamTrapUrl}
                                identifier={identifier}
                                actionTheme={actionTheme}
                                gtmFormType={gtmFormType}
                                l18n={l18n}
                                size={size}
                                formActors={formActors}
                            />
                        )}
                    </Container>
                )}
            </ThemeProvider>
        </Section>
    );
};

export default React.memo(FormSection);
